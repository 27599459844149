
import request from "@/utils/request";

export const addSilTransateFlow = (data) =>{
    return request.post(`/h5/api/silTransateFlow`,data);
}

export const updateAccount = (data) =>{
    return request.post(`/h5/api/updateAccount`,data);
}

export const listSilTransateFlow = (data) =>{
    return request.get(`/h5/api/TFList`,data);
}
