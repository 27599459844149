import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router/index';
import * as vant from 'vant';
import 'vant/lib/index.css';
import { Locale } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
import i18n from './lang/i18n';

Locale.use('en-US', enUS);

const app = createApp(App);

app.use(i18n);
app.use(router);
app.use(vant);

app.mount('#app');
