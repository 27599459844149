//这里放的是路由规则
import {createRouter,createWebHashHistory,} from 'vue-router'

//1.定义路由组件
import Withdraw from '@/pages/secondary_page/withdraw';
import Curve from '@/pages/secondary_page/curveData';


// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。
const routes = [
    { path: '/withdraw', component: Withdraw },
    { path: '/curve', component:Curve },
    { path: '/', component:()=>import('@/pages/Default') },
    { path: '/:pageMatch(.*)*', component:()=>import('@/pages/PageNotFound')},
    { path: '/home', component:()=>import('@/pages/Index') },
    { path: '/trade', component:()=>import('@/pages/Trade') },
    { path: '/login', component:()=>import('@/pages/Login') },
    { path: '/register', component:()=>import('@/pages/Register') },
    { path: '/bindBank', component:()=>import('@/pages/secondary_page/bindBankData') },
    { path: '/bindBankBegin', component:()=>import('@/pages/secondary_page/bindBankBegin') },
    { path: '/userInfo', component:()=>import('@/pages/secondary_page/userInfo') },
    { path: '/transactionRecords', component:()=>import('@/pages/secondary_page/transactionRecords') },
    { path: '/systemNotice', component:()=>import('@/pages/secondary_page/systemNoitce') },
    { path: '/giftRecord', component:()=>import('@/pages/secondary_page/giftRecord') },
  ]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
 })

router.beforeEach((to, from, next) => {
  next();
})
export default router
