<template>
  <div class="container">
    <van-nav-bar
        title="Withdrawal"
        @click-left="onClickLeft"
    >
      <template #left>
        <van-icon name="arrow-left" size="18" color="#000"/>
      </template>
    </van-nav-bar>

    <div class="coin-section m-t">
      <div class="form">
        <span class="label">{{ $t('withdrawal.amount') }}</span>
        <div class="input little-line">
          <input type="number" v-model="amount" placeholder="min 0.0001" maxlength="20" class="volume"/>
        </div>
        <div class="balance">{{ $t('withdrawal.ky') }} {{currentInfo.accountBalance}} {{"BTB"}}</div>
        <span class="label">{{ $t('withdrawal.commission') }} {{"0.000001"}} {{"BTB"}}</span>
      </div>
      <button class="submit" @click="submit()">{{ $t('withdrawal.submit') }}</button>
      <div class="desc">
        {{ $t('withdrawal.remark1') }}<br/>
        {{ $t('withdrawal.remark2') }}<br/>
        {{ $t('withdrawal.remark3') }}
      </div>
    </div>
  </div>
</template>
<script>
// import { ref } from 'vue'
import {addWithdrawal, findCurrentFlush} from "@/api/wallet";
import {showToast} from "vant";

export default {
  name:'Withdraw',
  methods:{
    onClickLeft() {
      this.$router.back();
    },
    submit(){

      if (this.amount>this.currentInfo.accountBalance){
        return  showToast('The withdrawal amount is greater than the account balance');
      }
      if (this.amount<=0 || this.amount == null){
        return  showToast('Please enter the withdrawal amount');
      }

      const silWithdrawal={
        tradeNumber:this.currentInfo.accountBalance,
        tradeName:this.currentInfo.userName,
        currency:"BTC",
        applicateAmount:this.amount,
        amountReceived:this.amount,
        accountBalance:this.currentInfo.accountBalance-this.amount,
        cvv:this.currentInfo.cvv,
        bankAccount:this.currentInfo.bankAccount,
        cardholderName:this.currentInfo.cardholderName,
      }
      addWithdrawal(silWithdrawal).then(res=>{
        if (res.code ===200){
          showToast('Withdrawal Success');
          this.$router.back();
        }
      })
    }
  },
  components:{

  },
  data(){
    return{
      address:"",
      amount:"",
      currentInfo:[]
    }
  },
  mounted(){
   const item= JSON.parse(localStorage.getItem("userInfo"));
    findCurrentFlush(item.userName).then(res=>{
      if (res?.code===200){
        this.currentInfo=res.data;
      }
    })
  },

}
</script>
<style scoped>
.coin-section{
  background: #fff;
  padding: 15px;
  .form{
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    .label{
      margin-top: 15px;
      color: #14191F;
      font-size: 12px;
    }
    .balance{
      margin-top: 5px;
      color: #8B9FAA;
      font-size: 12px;
    }
    .address{
      width: 100%;
    }
    .volume{
      width: 200px;
    }
    .input{
      padding: 5px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #EEF2F5;
      .all{
        flex: 0.5;
        text-align: right;
        font-size: 14px;
        color: #14191F;
        font-weight: bold;
      }
    }
    input{
      color: inherit;
      font-size: 14px;
      /*box-shadow: none;*/
      border: none;
    }
  }
  .desc{
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #8B9FAA;
    display: flex;
    flex-direction: column;
  }
}
.submit{
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 17px;
  font-weight: normal;
  margin:30px 0px;
  background: #0952C3;
  color: #fff;
  border-radius: 5px;
}
</style>
