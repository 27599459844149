// src/i18n.js
import { createI18n } from 'vue-i18n';

const messages = {
    en: {
        message: {
            withdraw: 'Withdrawal',
            currency: 'Currency',
        },
        index: {
            left: "Currency/circulating market value",
            middle: "Global Index",
            right: "Hourly Increase",
        },
        trade: {
            left: "Name",
            middle: "Current Price",
            right: "Ups and Downs",
        },
        wallet: {
            top: "Total Asset Conversion",
            btn1: "Withdrawal",
            btn2: "Information",
            btn3: "Withdrawal Details",
            bz: "B Type",
        },
        accountInfo: {
            bankCard: "Bank Card",
            bankCardNum: "Bank Cardholder Name",
            title: "If you want to modify your bank card, please contact the administrator",
        },
        withdrawal: {
            amount: "Amount",
            ky: "Available",
            commission: "Commission",
            submit: "Submit",
            remark1: "The minimum withdrawal amount is 0.0001 BTC",
            remark2: "To ensure the security of your funds, we will manually review withdrawals when your account security policy changes or password changes occur. Please be patient and wait for our staff to contact you via email.",
            remark3: "Please make sure to confirm the security of your computer and browser to prevent information from being tampered with or leaked.",
        },
        register:{
            p1:"Please enter your account",
            p2:"Please input a password",
            p3:"Please confirm a password",
            p4:"Register Now",
            p5:"Do you already have an account?",
            p6:"Login Now"
        },
        login:{
            p1: "Please enter your account",
            p2: "Please enter your  pwd",
            p3: "Login",
            p4: "Don't have an account yet?",
            p5: "Register Now",
        },
        cur:{
            p1:"Going long",
            p2:"Buy down"
        },
        center: {
            c1: "Welcome",
            c2: "Personal Information",
            c3: "Bank Card Information",
            c4: "System Notifications",
            c5: "Transaction Records",
            c6: "Benefits Center",
            c7: "Log Out"
        },
        newCenter:{
            n1:"Account balance",
            n2:"Bank card number",
            n3:"Registration days",
            n4:"The product is currently on hot sale",
            n5:"View now",
            n6:"ACCOUNT",
            n7:"Membership distribution",
            n8:"Other services"
        },
        newAccount:{
            p1:"SilTransateFlow",
            p2:"Distributions",
            p3:"account info"
        },
        order:{
            p1:"order",
            p2:"trades"
        },
        detail: {
            p1:"New Bitcoin Wallet"
        },
        gift:{
            p1:"Send You",
            p2:"Distributions"
        },
    },
    pl: {
        message: {
            withdraw: 'Wypłata',
            currency: 'Waluta',
        },
        index: {
            left: "Waluta/obrotowa wartość rynkowa",
            middle: "Globalny indeks",
            right: "Godzinowe zwiększenie",
        },
        trade: {
            left: "Nazwa",
            middle: "Aktualna cena",
            right: "Zmiany",
        },
        wallet: {
            top: "Konwersja całkowitych aktywów",
            btn1: "Wypłata",
            btn2: "Informacje",
            btn3: "Szczegóły wypłaty",
            bz: "Zmiany",
        },
        accountInfo: {
            bankCard: "Karta bankowa",
            bankCardNum: "Nazwa posiadacza karty",
            title: "Jeśli chcesz zmienić swoją kartę bankową, skontaktuj się z administratorem",
        },
        withdrawal: {
            amount: "Kwota",
            ky: "Dostępne",
            commission: "Komisja",
            submit: "Wyślij",
            remark1: "Minimalna kwota wypłaty wynosi 0.0001 BTC",
            remark2: "Aby zapewnić bezpieczeństwo Twoich środków, będziemy ręcznie przeglądać wypłaty, gdy zmienią się polityki bezpieczeństwa konta lub hasła. Proszę cierpliwie czekać na kontakt naszego personelu poprzez e-mail.",
            remark3: "Upewnij się, że potwierdziłeś bezpieczeństwo swojego komputera i przeglądarki, aby zapobiec modyfikacji lub ujawnieniu informacji.",
        },
        register: {
            p1: "Proszę wprowadzić swoje konto",
            p2: "Proszę wprowadzić hasło",
            p3: "Proszę potwierdzić hasło",
            p4: "Zarejestruj się teraz",
            p5: "Czy już masz konto?",
            p6: "Zaloguj się teraz",
            p7: "Zarejestruj",
        },
        login: {
            p1: "Proszę wprowadzić swoje konto",
            p2: "Proszę wprowadzić hasło",
            p3: "Zaloguj się",
            p4: "Nie masz jeszcze konta?",
            p5: "Zarejestruj się teraz",
        },
        "cur": {
            "p1": "Otwieranie długiej pozycji",
            "p2": "Zmniejszanie kosztów kupna"
        },
        center: {
            c1: "Witamy",
            c2: "Informacje Osobiste",
            c3: "Informacje o Karcie Bankowej",
            c4: "Powiadomienia Systemowe",
            c5: "Rekordy Transakcji",
            c6: "Centrum Promocji",
            c7: "Wyloguj"
        },
        newCenter: {
            n1: "Saldo konta", // Account balance
            n2: "Numer karty bankowej", // Bank card number
            n3: "Dni od rejestracji", // Registration days
            n4: "Produkt jest obecnie w promocji", // The product is currently on hot sale
            n5: "Zobacz", // View now
            n6: "KONTO", // ACCOUNT
            n7: "Rozkład członkostwa", // Membership distribution
            n8: "Inne usługi" // Other services
        },
        newAccount: {
            p1: "PrzepływTransakcji",
            p2: "Rozkład", // Distributions
            p3: "Informacje o koncie" // account info
        },
        order: {
            p1: "Zamówienie", // order
            p2: "Transakcje" // trades
        },
        detail: {
            p1: "Nowy portfel Bitcoin", // New Bitcoin Wallet
        },
        gift: {
            p1: "Wyślij Tobie", // Send You
            p2: "Rozdanie" // Distributions
        }
    },
    es: {
        message: {
            "withdraw": 'Retiro',
            "currency": 'Moneda',
        },
        index: {
            "left": "Valor de mercado en circulación / Moneda",
            "middle": "Índice Global",
            "right": "Aumento Horario",
        },
        trade: {
            "left": "Nombre",
            "middle": "Precio Actual",
            "right": "Subidas y Bajadas",
        },
        wallet: {
            "top": "Conversión de Activos Totales",
            "btn1": "Retiro",
            "btn2": "Información",
            "btn3": "Detalles del Retiro",
            "bz": "Tipo B",
        },
        accountInfo: {
            "bankCard": "Tarjeta Bancaria",
            "bankCardNum": "Nombre del Titular de la Tarjeta Bancaria",
            "title": "Si desea modificar su tarjeta bancaria, por favor póngase en contacto con el administrador",
        },
        withdrawal: {
            "amount": "Cantidad",
            "ky": "Disponible",
            "commission": "Comisión",
            "submit": "Enviar",
            "remark1": "El monto mínimo de retiro es 0.0001 BTC",
            "remark2": "Para garantizar la seguridad de sus fondos, revisaremos manualmente los retiros cuando cambie la política de seguridad de su cuenta o su contraseña. Por favor, tenga paciencia y espere que nuestro personal se comunique con usted por correo electrónico.",
            "remark3": "Por favor, asegúrese de confirmar la seguridad de su computadora y navegador para evitar que la información sea modificada o filtrada.",
        },
        register: {
            "p1": "Por favor ingrese su cuenta",
            "p2": "Por favor ingrese una contraseña",
            "p3": "Por favor confirme la contraseña",
            "p4": "Regístrese Ahora",
            "p5": "¿Ya tiene una cuenta?",
            "p6": "Inicie Sesión Ahora"
        },
        login: {
            "p1": "Por favor ingrese su cuenta",
            "p2": "Por favor ingrese su contraseña",
            "p3": "Iniciar Sesión",
            "p4": "¿Aún no tiene una cuenta?",
            "p5": "Regístrese Ahora",
        },
        cur: {
            "p1": "Compra a largo plazo",
            "p2": "Compra a la baja"
        },
        center: {
            "c1": "Bienvenido",
            "c2": "Información Personal",
            "c3": "Información de la Tarjeta Bancaria",
            "c4": "Notificaciones del Sistema",
            "c5": "Historial de Transacciones",
            "c6": "Centro de Beneficios",
            "c7": "Cerrar Sesión"
        },
        newCenter: {
            "n1": "Saldo de la Cuenta",
            "n2": "Número de Tarjeta Bancaria",
            "n3": "Días de Registro",
            "n4": "El producto está actualmente en oferta",
            "n5": "Ver Ahora",
            "n6": "CUENTA",
            "n7": "Distribución de Miembros",
            "n8": "Otros Servicios"
        },
        newAccount: {
            "p1": "Flujo de SilTransate",
            "p2": "Distribuciones",
            "p3": "Información de la Cuenta"
        },
        order: {
            "p1": "Orden",
            "p2": "Transacciones"
        },
        detail: {
            "p1": "Nuevo Monedero de Bitcoin"
        },
        gift: {
            "p1": "Enviado a Usted",
            "p2": "Distribuciones"
        },
    }
};

const i18n = createI18n({
    locale: 'pl', // 默认语言
    messages,
});

export default i18n;
