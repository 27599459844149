//导入工具类
import request from "@/utils/request";

//交易流水
export const getWithdrawal = (tradeName) =>{
    return request.get(`/withdrawal/withdrawal/list?tradeName=${encodeURIComponent(tradeName)}`);
}


export const addWithdrawal = (data) =>{
    return request.post(`/withdrawal/withdrawal`,data);
}


export const findCurrentFlush = (username) =>{
    return request.get(`/h5/api/findCurrentFlush?username=${encodeURIComponent(username)}`);
}
