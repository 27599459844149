import Vuex from 'vuex'

export const store = new Vuex.Store({
    state () {
      return {
        isLogin:false,//用户是否登录
      }
    },
    mutations:{
        login(state){//登录
          state.isLogin = true;//全局状态控制用户是否登录
        },
        logout(state){//退出登录
          state.isLogin =false;
        },
    }
  })

